import React from 'react';
import Button from '@material-ui/core/Button';

import { withAuth0 } from '@auth0/auth0-react';

class LoginButton  extends React.Component {

  render() {
    const { loginWithRedirect } = this.props.auth0;

    return (
      <Button variant="contained" color="secondary" onClick={() => loginWithRedirect()}>Log In</Button>
    );
  }
}

export default withAuth0(LoginButton);
