import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { useAuth0 } from "@auth0/auth0-react";

import API from './../api/API';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function SerachBar(props) {
  const {
    onSuggestionSelected,
    onSearch
  } = props;

  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const { getAccessTokenSilently } = useAuth0();


  const fetch = React.useMemo(
    () =>
      throttle(async (request, callback) => {

        const token = await getAccessTokenSilently();

        const response = await API.get('/api/v1/leisure-directory/autosuggest'
            + `?q=${request.queryStr}`
            + `&focus.lat=${request.at.lat}`
            + `&focus.lng=${request.at.lng}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        console.log('autosuggest response:');
        console.log(response);
        callback(response.data.items);

      }, 200),
    [ getAccessTokenSilently ],
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ queryStr: inputValue, at: { lat: 51.437298, lng: 7.337315} }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        console.log('value: ' + value);

        console.log('results:');
        console.log(results);
        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      style={{ padding: 15 }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.title)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      freeSolo
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);

        console.log('on change: ' + newValue);
        console.log(newValue);

        if (newValue === null) {
          // Cleared
          return;
        }

        if ((typeof newValue) === 'string') {
          // TextField submitted (enter pressed)
          onSearch(newValue);
        } else {
          // Suggestion selected
          onSuggestionSelected(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Suchst du etwas bestimmtes?" variant="outlined" fullWidth />
      )}
      renderOption={(option) => {
        console.log('rendering option:');
        console.log(option);

        if (option === undefined || (typeof option) === 'string') {
          return option;
        }

        const matches = option.highlights;
        const parts = parse(
          option.title,
          matches.map((match) => [match.start, match.end]),
        );

        var secondaryText;
        if (option.resultType === 'place') {
          const address = option['_embedded']['bdy:place'].address;
          secondaryText = `(${option.resultType}) ${address.street} ${address.houseNumber}, ${address.postalCode} ${address.city}`
        } else {
          secondaryText = `(${option.resultType})`
        }

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {secondaryText}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
