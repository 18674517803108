import axios from 'axios';

console.log('Environment: ' + process.env.REACT_APP_ENV);

var apiBaseURL = process.env.REACT_APP_API_URL;
console.log('API base URL: ' + apiBaseURL);

export default axios.create({
  baseURL: apiBaseURL,
  responseType: 'json'
});
