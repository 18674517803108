import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

import PlaceService from './core/PlaceService';

import PlaceExclusionDetails from './leisuredirectory/PlaceExclusionDetails';


const styles = {
  root: {
  },
  media: {
    height: 140,
  },
  table: {
    minWidth: 650,
  },
  idCell: {

  }
};

class PlaceDetails extends React.Component {

  categoryImageMap = new Map([
    [PlaceService.ACTION, '/images/category_action.png'],
    [PlaceService.FOOD_DRINK, '/images/category_food.png'],
    [PlaceService.NIGHTLIFE, '/images/category_nightlife.png'],
    [PlaceService.CULTURE, '/images/category_culture.png'],
    [PlaceService.WELLNESS, '/images/category_wellness.png']
  ]);

  categoryNameMap = new Map([
    [PlaceService.ACTION, 'Action'],
    [PlaceService.FOOD_DRINK, 'Food & Drink'],
    [PlaceService.NIGHTLIFE, 'Nightlife'],
    [PlaceService.CULTURE, 'Kultur'],
    [PlaceService.WELLNESS, 'Wellness']
  ]);

  placeService = new PlaceService();

  render() {
    const { classes } = this.props;

    const {
      place,
      onPlaceExcluded,
      onPlaceExclusionStopped
    } = this.props;

    const secondaryCategories = place.categories.all.filter(c => c !== place.categories.primary);

    return (
      <Card className={classes.root}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {place.excluded && '[Ausgeblendet]: '} {place.name}
          </Typography>
          {
            <Chip
              avatar={<Avatar src={this.categoryImageMap.get(place.categories.primary)} />}
              label={this.categoryNameMap.get(place.categories.primary)}
              variant="outlined"
              color="secondary"
               />
          }
          {
            secondaryCategories.map(c => (
              <Chip avatar={<Avatar src={this.categoryImageMap.get(c)} />} label={this.categoryNameMap.get(c)} />
            ))
          }
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                    </TableCell>
                  <TableCell align="left" className={classes.idCell}>{place.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Location
                    </TableCell>
                  <TableCell align="left">{place.location.latitude}, {place.location.longitude}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Address
                    </TableCell>
                  <TableCell align="left">
                    {place.address.street} {place.address.houseNumber}<br />
                    {place.address.postalCode} {place.address.city}
                    </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <h3>HERE Categories</h3>
          <Divider />
          <List dense={true}>
            {
              (place.providerMetaData.categoryMappingDetails || []).map(mappingInfo =>
                <ListItem key={mappingInfo.hereCategory.id}>
                  <ListItemText
                    primary={mappingInfo.hereCategory.name + (mappingInfo.hereCategory.primary ? ' (primary)' : '')}
                    secondary={mappingInfo.hereCategory.id + ' (mapped -> ' + mappingInfo.mappedCategories.join(', ') + ')'}
                  />
              </ListItem>
              )
            }
          </List>
          <Divider />
          <PlaceExclusionDetails
            place={place}
            onPlaceExcluded={onPlaceExcluded}
            onPlaceExclusionStopped={onPlaceExclusionStopped}
          />
        </CardContent>
        <CardActions>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(PlaceDetails);
