import React, { Component } from 'react';
import Navbar from './Navbar';
import { Grid } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { withAuth0 } from "@auth0/auth0-react";

import MapPosition from './map/MapPosition';
import HereMap from './map/HereMap';
import PlaceDetails from './PlaceDetails';
import HereCategoriesView from './core/HereCategoriesView';
import BuddyCategoriesFilter from './map/BuddyCategoryFilters';
import SearchBar from './map/SearchBar';

import LoginButton from './auth/LoginButton';

import PlaceService from './core/PlaceService';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

class App extends Component {

  placeService = new PlaceService();

  constructor(props) {
    super(props);
    this.state = {
      zoom: 14,
      lat: 51.437298,
      lng: 7.337315,
      selectedPlace: null,
      categoryMapping: {},
      reverseCategoryMapping: new Map(),
      mapController: {}
    };
  }

  handleInputChange = (name, value) => {
    this.setState({
      [name]: value
    });
  }

  handleMapViewChange = (zoom, lat, lng) => {
    this.setState({
      lat,
      lng,
      zoom
    });
  }

  handlePlaceTap = (place) => {
    this.setState({
      selectedPlace: place
    });
  }

  handleCategoryFilterChange = (activeCategories) => {
    console.log('foo');
    this.state.mapController.updateFilter(activeCategories);
  }

  handleSuggestionSelected = (suggestion) => {
    if (suggestion.resultType === 'place') {
      console.log('single place suggestion selected');

      const place = suggestion['_embedded']['bdy:place'];
      this.state.mapController.showPlaces([place]);

    } else if (suggestion.resultType === 'locality') {

      this.state.mapController.showLocality(suggestion);
    }
  }

  handleSearch = async (searchQuery) => {
    const {
      getAccessTokenSilently
    } = this.props.auth0;

    const {
      lat,
      lng
    } = this.state;

    if (searchQuery !== undefined && searchQuery !== '') {

      const results = await this.placeService.search(searchQuery, { lat, lng }, getAccessTokenSilently);

      if (results.length === 0) {
        // No results
        return [];
      } else {
        const firstResultType = results[0].resultType;
        if (firstResultType === 'locality') {
          // Move map to locality
          console.log('showing locality result');
          if (results.length > 1) {
            console.log('WARN: more than 1 locality matching search. TODO let user select');
          }
          this.state.mapController.showLocality(results[0]);

        } else {
          // Show all place results on map

          const places = [];
          for (const result of results) {
            if (result.resultType === 'place') {
              places.push(result['_embedded']['bdy:place']);
            }
          }
          console.log(`showing ${places.length} palces from ${results.length} total results`);
          this.state.mapController.showPlaces(places);
        }
      }
    }
  }

  onCategoryMappingChanged = (categoryMapping) => {
    const reverseMapping = new Map();
    for (const [buddyCat, hereCategories] of Object.entries(categoryMapping)) {
      for (const hereCat of hereCategories) {
        var l = reverseMapping.get(hereCat);
        if (l === undefined) {
          l = [];
          reverseMapping.set(hereCat, l);
        }
        // Add buddy category to the reverse mapping of this HERE category
        l.push(buddyCat);
      }
    }
    this.setState({
      categoryMapping: categoryMapping,
      reverseCategoryMapping: reverseMapping,
    });
  }

  /**
   *Called from PlaceDetails component after place was excluded.
   *
   * @param  {[type]} place [description]
   * @return {[type]}       [description]
   */
  onPlaceExcluded = (place) => {
    this.state.mapController.markPlaceExcluded(place, true);

    // trigers a refresh of PalceDetailsView
    this.setState((prevState) => {
      return {
        selectedPlace: prevState.selectedPlace,
      };
    });
  }

  onPlaceIncluded = (place) => {
    this.state.mapController.markPlaceExcluded(place, false);

    // trigers a refresh of PalceDetailsView
    this.setState((prevState) => {
      return {
        selectedPlace: prevState.selectedPlace,
      };
    });
  }

  render() {
    const { classes } = this.props;

    const {
      isAuthenticated,
      isLoading,
      error
    } = this.props.auth0;

    return (
      <div className={classes.root}>
        <Navbar />
        {error && this.renderAuthError()}
        {isLoading && this.renderLoading()}
        {!isAuthenticated && this.renderNotAuthenticated()}
        {isAuthenticated && this.renderAuthenticated()}
      </div>
    );
  }

  renderLoading() {
    const { classes } = this.props;

    return (
      <Grid container spacing={3} style={{ padding: '15px' }}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>Loading...</Paper>
        </Grid>
      </Grid>
    );
  }

  renderAuthError() {
    const { classes } = this.props;

    const {
      error
    } = this.props.auth0;

    console.log('Auth0 authentication error:');
    console.log(error);

    return (
      <Grid container spacing={3} style={{ padding: '15px' }}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>Auth error: {error.toString()}</Paper>
        </Grid>
      </Grid>
    );
  }

  renderNotAuthenticated() {
    const { classes } = this.props;

    return (
      <Grid container spacing={3} style={{ padding: '15px' }}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <h2>Welcome 🐻</h2>
            <p>Bitte melde Dich mit deinen internen BUDDY Zugangsdaten an.</p>
            <LoginButton />
          </Paper>
        </Grid>
      </Grid>
    );
  }

  renderAuthenticated() {
    const { classes } = this.props;

    const {
      lat,
      lng,
      zoom,
      selectedPlace,
      categoryMapping,
      reverseCategoryMapping,
      mapController,
    } = this.state;

    return (
      <Grid container spacing={3} style={{ padding: '15px' }}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>Welcome 🐻</Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <SearchBar
              onSuggestionSelected={this.handleSuggestionSelected}
              onSearch={this.handleSearch}
              />
            <BuddyCategoriesFilter
              onChange={this.handleCategoryFilterChange}
            />
            <HereMap
              lat={lat}
              lng={lng}
              zoom={zoom}
              onMapViewChange={this.handleMapViewChange}
              onPlaceTap={this.handlePlaceTap}
              categoryMapping={categoryMapping}
              reverseCategoryMapping={reverseCategoryMapping}
              controller={mapController}
            />
            <MapPosition
              lat={lat}
              lng={lng}
              zoom={zoom}
              onChange={this.handleInputChange}
            />
          <Button onClick={() => {mapController.clearPlaces()}}>Clear Map</Button>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          {selectedPlace != null ?
            (<PlaceDetails
                place={selectedPlace}
                onPlaceExcluded={this.onPlaceExcluded}
                onPlaceExclusionStopped={this.onPlaceIncluded}
                />)
            : (<Paper className={classes.paper}>
              Kein Ort ausgewählt
          </Paper>)}
        </Grid>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={11}>
            <HereCategoriesView
              onCategoryMappingChanged={this.onCategoryMappingChanged}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withAuth0(withStyles(styles, { withTheme: true })(App));
