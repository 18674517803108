import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

import PlaceService from './../core/PlaceService';

class BuddyCategoriesFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: [
        PlaceService.ACTION,
        PlaceService.CULTURE,
        PlaceService.FOOD_DRINK,
        PlaceService.WELLNESS,
        PlaceService.NIGHTLIFE,
        PlaceService.OTHER,
      ]
    }
  }

  handleCategoryClick = (category) => {
    const {
      onChange
    } = this.props;

    this.setState((prevState) => {
      const nextSelected = prevState.selected.slice();
      const index = nextSelected.indexOf(category);
      if (index === -1) {
        nextSelected.push(category);
      } else {
        nextSelected.splice(index, 1);
      }

      onChange(nextSelected);

      return { selected: nextSelected };
    });
  }

  render() {
    const {
      selected
    } = this.state

    return (
      <>
      <CategoryChip
        categoryId={PlaceService.ACTION}
        name="action"
        icon="/images/category_action.png"
        selected={selected}
        onClick={this.handleCategoryClick}
      />
      <CategoryChip
        categoryId={PlaceService.CULTURE}
        name="Kultur"
        icon="/images/category_culture.png"
        selected={selected}
        onClick={this.handleCategoryClick}
      />
      <CategoryChip
        categoryId={PlaceService.FOOD_DRINK}
        name="Food & Drink"
        icon="/images/category_food.png"
        selected={selected}
        onClick={this.handleCategoryClick}
      />
      <CategoryChip
        categoryId={PlaceService.WELLNESS}
        name="Wellness"
        icon="/images/category_wellness.png"
        selected={selected}
        onClick={this.handleCategoryClick}
      />
      <CategoryChip
        categoryId={PlaceService.NIGHTLIFE}
        name="Nightlife"
        icon="/images/category_nightlife.png"
        selected={selected}
        onClick={this.handleCategoryClick}
      />
      </>
    );
  }
}

function CategoryChip(props) {
  const {
    categoryId,
    icon,
    name,
    selected,
    onClick
  } = props;

  return (
    <Chip
      avatar={<Avatar src={icon} />}
      label={name}
      variant={selected.includes(categoryId) ? 'default' : 'outlined'}
      color={selected.includes(categoryId) ? 'primary' : 'default'}
      onClick={(event) => {onClick(categoryId)}}
    />
  );
}

export default BuddyCategoriesFilter;
