import React from 'react';

export default class MapPosition extends React.Component {

  handleOnChange = (e) => {
    const {
      onChange
    } = this.props;
    // Pass values to parent compoennt
    onChange(e.target.name, e.target.value);
  }

  render() {
    const {
      lat,
      lng,
      zoom
    } = this.props;

    return (
      <>
        <div>
          Zoom:
          <input
            onChange={this.handleOnChange}
            name="zoom"
            type="number"
            value={zoom}
          />
        </div>
        <div>
          Latitude:
          <input
            onChange={this.handleOnChange}
            name="lat"
            type="number"
            value={lat}
          />
        </div>
        <div>
          Longitude:
          <input
            onChange={this.handleOnChange}
            name="lng"
            type="number"
            value={lng}
          />
        </div>
      </>
    );
  }
}
