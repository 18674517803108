import * as React from 'react';
import onResize from 'simple-element-resize-detector';

import PlaceService from './../core/PlaceService';

import { withAuth0 } from '@auth0/auth0-react';

class HereMap extends React.Component {

  iconBlue = new window.H.map.Icon('images/mapicon_blue.png');
  iconGreen = new window.H.map.Icon('images/mapicon_green.png');
  iconOrange = new window.H.map.Icon('images/mapicon_orange.png');
  iconPink = new window.H.map.Icon('images/mapicon_pink.png');
  iconYellow = new window.H.map.Icon('images/mapicon_yellow.png');
  iconGrey = new window.H.map.Icon('images/mapicon_grey-temp.png');
  iconExcluded = new window.H.map.Icon('images/mapicon_excluded.png');

  iconBlueSelected = new window.H.map.Icon('images/mapicon_blue_selected.png');
  iconGreenSelected = new window.H.map.Icon('images/mapicon_green_selected.png');
  iconOrangeSelected = new window.H.map.Icon('images/mapicon_orange_selected.png');
  iconPinkSelected = new window.H.map.Icon('images/mapicon_pink_selected.png');
  iconYellowSelected = new window.H.map.Icon('images/mapicon_yellow_selected.png');
  iconGreySelected = new window.H.map.Icon('images/mapicon_grey-temp_selected.png');
  iconExcludedSelected = new window.H.map.Icon('images/mapicon_excluded_selected.png');

  categoryIconMap = new Map([
    [PlaceService.ACTION, this.iconOrange],
    [PlaceService.FOOD_DRINK, this.iconGreen],
    [PlaceService.NIGHTLIFE, this.iconPink],
    [PlaceService.CULTURE, this.iconYellow],
    [PlaceService.WELLNESS, this.iconBlue],
    [PlaceService.OTHER, this.iconGrey]
  ]);

  selectedCategoryIconMap = new Map([
    [PlaceService.ACTION, this.iconOrangeSelected],
    [PlaceService.FOOD_DRINK, this.iconGreenSelected],
    [PlaceService.NIGHTLIFE, this.iconPinkSelected],
    [PlaceService.CULTURE, this.iconYellowSelected],
    [PlaceService.WELLNESS, this.iconBlueSelected],
    [PlaceService.OTHER, this.iconGreySelected]
  ]);

  mapRef = React.createRef();

  placeService = new PlaceService();

  /**
   * The current selectedPlace (palce for which details are shown).
   * undefined if no selected.
   *
   * Used to change the marker icon of the last selected palce after new one was
   * selected.
   */
  selectedPlace;

  constructor(props) {
    super(props);
    const {
      controller
    } = this.props;

    if (controller) {
      controller.clearPlaces = () => {
        console.log('clearing map');
        this.state.places.clear();
        this.state.placeGroup.removeAll();

        // enable nearby places again
        this.setState({showingResults: false});
      }

      controller.removePlace = (place) => {
        console.log('removing place from map: ' + place.id);

        this.setState(prevState => {

          const newPlaces = new Map(prevState.places);
          newPlaces.delete(place.id);

          const newFilteredPlaces = new Map(prevState.filteredPalces);
          newFilteredPlaces.delete(place.id);

          console.log('marker:');
          console.log(place.marker);
          if (prevState.placeGroup.contains(place.marker)) {
            prevState.placeGroup.removeObject(place.marker);
          }

          return {
            places: newPlaces,
            filteredPlaces: newFilteredPlaces
          };
        });
      }

      controller.showPlaces = (places) => {
        console.log('showing search results');

        // first clear
        this.state.places.clear();
        this.state.placeGroup.removeAll();

        this.addPlaces(places);


        if (places.length === 1) {
          // Focus on single place
          const center = places[0].location;
          this.state.map.getViewModel().setLookAtData({
            position: {lat: center.latitude, lng: center.longitude}
          }, true);

        } else {
          // Show all places
          this.state.map.getViewModel().setLookAtData({
            bounds: this.state.placeGroup.getBoundingBox()
          }, true);
        }

        this.setState({showingResults: true});
      }

      controller.showLocality = (locality) => {
        console.log('showing locality');

        const bounds = locality.preferredViewport;

        var bbox = new window.H.geo.Rect(
          bounds.northEast.latitude,
          bounds.southWest.longitude,
          bounds.southWest.latitude,
          bounds.northEast.longitude);

        this.state.map.getViewModel().setLookAtData({
          bounds: bbox
        }, true);
      }

      controller.updateFilter = (activeCategories) => {
        console.log('Map: changing filter: ' + activeCategories);
        this.setState(prevState => {
          // creates new array with filtered places
          const filteredPlaces = new Map();

          for (const [id, place] of prevState.places) {
            const matchesFilter = place.categories.all.some(c => activeCategories.includes(c));
            if (!matchesFilter) {
              // remove the actual marker from map
              if (prevState.placeGroup.contains(place.marker)) {
                prevState.placeGroup.removeObject(place.marker);
              }
            } else {
              filteredPlaces.set(id, place);
              if (!prevState.placeGroup.contains(place.marker)) {
                // add to map
                prevState.placeGroup.addObject(place.marker);
              }
            }
          }

          return {
            filteredPlaces: filteredPlaces,
            activeCategories: activeCategories
          };
        });
      }

      controller.markPlaceExcluded = (place, excludedFlag) => {
        // no actual state modification

        place.excluded = excludedFlag;
        place.marker.setIcon(this.getPlaceIcon(place));
      }
    }
  }

  state = {
    // The map instance to cleanup later
    map: null,
    // map of placeId -> place
    places: new Map(),
    filteredPalces: new Map(),
    activeCategories: [
      PlaceService.ACTION,
      PlaceService.CULTURE,
      PlaceService.FOOD_DRINK,
      PlaceService.WELLNESS,
      PlaceService.NIGHTLIFE,
      PlaceService.OTHER,
    ],
    showingResults: false
  };

  handleMapViewChange = (e) => {
    const {
      onMapViewChange
    } = this.props;

    if (e.newValue && e.newValue.lookAt) {
      const lookAt = e.newValue.lookAt;
      // adjust position
      const lat = Math.trunc(lookAt.position.lat * 1e7) / 1e7;
      const lng = Math.trunc(lookAt.position.lng * 1e7) / 1e7;
      const zoom = Math.trunc(lookAt.zoom * 1e2) / 1e2;
      onMapViewChange(zoom, lat, lng);

      // Load more places
      // .. debounce
      clearTimeout(this.placeLoadTimeout);
      this.placeLoadTimeout = setTimeout(() => {

        // Only update nearby palces if not showing results
        if (!this.state.showingResults) {
          const location = this.state.map.getCenter();
          console.log('loading more places at: ' + location);
          this.updatePlaces(location);
        }
      }, 500);
    }
  }

  // Helper for logging events
  handlePlaceTap = (e) => {
    const {
      onPlaceTap
    } = this.props;

    var tappedPlace = e.target.getData();

    /*
     * Change marker to selected
     *
     */
    if (this.selectedPlace) {
      this.selectedPlace.marker.setIcon(this.getPlaceIcon(this.selectedPlace));
    }
    tappedPlace.marker.setIcon(this.getSelectedPlaceIcon(tappedPlace));
    /*
     * NOTE: We do not store selected palce in state. Is this a good idea??
     */
    this.selectedPlace = tappedPlace;

    var message = ['event "', e.type, '" @ ' + tappedPlace.title].join('');
    console.log(message);
    onPlaceTap(tappedPlace);
  }

  componentDidMount() {

    const H = window.H;
    const platform = new H.service.Platform({
      apikey: 'DgMN-mQy8ipvJSCEgQW7Ezupo9s4L_MqLjnUJArIRwU'
    });

    const defaultLayers = platform.createDefaultLayers();

    // Create map instance
    const map = new H.Map(
      this.mapRef.current,
      defaultLayers.vector.normal.map,
      {
        // Center over Witten
        center: { lat: 51.437298, lng: 7.337315 },
        zoom: 14,
        pixelRation: window.devicePixelRatio || 1
      }
    );

    onResize(this.mapRef.current, () => {
      map.getViewPort().resize();
    });

    // attach listener
    map.addEventListener('mapviewchange', this.handleMapViewChange);

    // MapEvents enables the event system
    // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
    // This variable is unused and is present for explanatory purposes
    new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

    // Create the default UI components to allow the user to interact with them
    // This variable is unused
    H.ui.UI.createDefault(map, defaultLayers, 'de-DE');


    // SETUP marker




    // add markers
    const placeGroup = new H.map.Group();
    map.addObject(placeGroup);

    this.setState({
      map,
      placeGroup
    }, () => {
      const requestCenter = { lat: 51.437298, lng: 7.337315 };
      this.updatePlaces(requestCenter);
    });
  }

  updatePlaces = async (location) => {
    console.log('Updating places');
    const {
      getAccessTokenSilently
    } = this.props.auth0;

    const {
      activeCategories
    } = this.state;

    console.log('active categories: ' + activeCategories);

    if (activeCategories.length === 0) {
      console.log('skipping request: no categories selected');
      return;
    }

    // Load palces from API
    const nearbyResult = await this.placeService.loadNearbyPlaces(location, activeCategories, getAccessTokenSilently);
    console.log(`found ${nearbyResult.excludedPlaces.length} excluded places`);

    // add normal palce results
    this.addPlaces(nearbyResult.places);

    for (const excludedPlace of nearbyResult.excludedPlaces) {
      excludedPlace.excluded = true;
    }

    // add excluded places
    this.addPlaces(nearbyResult.excludedPlaces);
  }

  addPlaces = (places) => {
    const H = window.H;
    var newPlaces = 0;
    for (const place of places) {
      if (!this.state.places.has(place.id)) {

        var icon = this.getPlaceIcon(place);

        // TODO Rename latitude, longtude in APi to lat, lng
        var marker = new H.map.Marker({ lat: place.location.latitude, lng: place.location.longitude }, { icon: icon });
        marker.setData(place);
        marker.addEventListener('tap', this.handlePlaceTap);

        // attach marker to palce so we can access the marker MapObject easy on remove
        place.marker = marker;

        // Store with ALL unfiltered places
        this.state.places.set(place.id, place);

        // All categories assigned to the place
        const placeCategories = place.categories.all;

        const matchesFilter = placeCategories.some(c => this.state.activeCategories.includes(c));
        if (matchesFilter) {
          this.state.filteredPalces.set(place.id, place);
          this.state.placeGroup.addObject(marker);
        }

        newPlaces++;
      }
    }

    console.log(`added ${newPlaces} new places`);
  }

  getPlaceIcon = (place) => {
    if (place.excluded) {
      return this.iconExcluded;
    }

    const primaryCategory = place.categories.primary;
    return this.categoryIconMap.get(primaryCategory);
  }

  getSelectedPlaceIcon = (place) => {
    if (place.excluded) {
      return this.iconExcludedSelected;
    }

    const primaryCategory = place.categories.primary;
    return this.selectedCategoryIconMap.get(primaryCategory);
  }

  componentDidUpdate() {
    const {
      lat,
      lng,
      zoom
    } = this.props;

    if (this.state.map) {
      // debounce setZoom and setCenter calls
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.state.map.setZoom(zoom);
        this.state.map.setCenter({ lat, lng });
      }, 500);



    }
  }

  componentWillUnmount() {
    // cleanup to avoid memory leak
    if (this.state.map) {
      this.state.map.removeEventListener('mapviewchange', this.handleMapViewChange);
      this.state.map.dispose();
    }
  }

  render() {
    return (
      // Set a height so the map will display
      <div ref={this.mapRef} style={{ height: '500px' }} />
    );
  }
}

export default withAuth0(HereMap);
