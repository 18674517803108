import API from './../api/API';

export default class PlaceService {

  static get ACTION() { return 'ACTION' }
  static get FOOD_DRINK() { return 'FOOD_DRINK' }
  static get NIGHTLIFE() { return 'NIGHTLIFE' }
  static get CULTURE() { return 'CULTURE' }
  static get WELLNESS() { return 'WELLNESS' }
  static get OTHER() { return 'OTHER' }

  static get CATEGORIES() { return [
    PlaceService.ACTION,
    PlaceService.FOOD_DRINK,
    PlaceService.NIGHTLIFE,
    PlaceService.CULTURE,
    PlaceService.WELLNESS
  ] }

  /**
   * Load nearby places from the BUDDY API.
   *
   * @param  {[type]}  center         center to load nearby places
   * @param  {[type]}  categories     the categories to search for
   * @param  {[type]}  getAccessToken async function that returns an access token
   * @return {Promise}                (promise) a list of found places
   */
  loadNearbyPlaces = async (center, categories, getAccessToken) => {
    console.log(`Loading nearby places from API (lat=${center.lat}, lng=${center.lng})`);

    const categoriesStr = categories.join(',');

    try {
      const token = await getAccessToken();

      const response = await API.get('/api/v1/leisure-directory/nearby'
          + `?location.lat=${center.lat}`
          + `&location.lng=${center.lng}`
          + `&categories=${categoriesStr}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      /*
       * Although palces are returned as a paginated collection, there is always
       * only a first page! (HERE limits us to 100 results anyway)
       */
      // embedded relation is 'item' (default collection rel)
      const places = response.data['_embedded'].item;
      console.log(`Loaded ${places.length} places.`);

      var excludedPlaces = response.data['_embedded']['bdy:excluded-places'];
      if (excludedPlaces === undefined) {
        excludedPlaces = [];
      }
      if (!Array.isArray(excludedPlaces)) {
        excludedPlaces = [excludedPlaces];
      }

      return {
        places: places,
        excludedPlaces: excludedPlaces
      };
    } catch (error) {
      console.log('ERROR when loading nearby palces:')
      console.log(error);
      throw error;
    }
  }

  /**
   * Load nearby places from the BUDDY API.
   *
   * @param  {String}  query         the query to search for
   * @param  {LatLng}  focusPoint     the location to focus results on
   * @param  {[type]}  getAccessToken async function that returns an access token
   * @return {Promise}                (promise) a list of found places
   */
  search = async (query, focus, getAccessToken) => {
    console.log(`Searching (query=${query}, focus.lat=${focus.lat}, focus.lng=${focus.lng})`);

    try {
      const token = await getAccessToken();

      const response = await API.get('/api/v1/leisure-directory/search'
          + `?focus.lat=${focus.lat}`
          + `&focus.lng=${focus.lng}`
          + `&q=${query}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      const results = response.data.items;
      console.log(`Got ${results.length} search results.`);

      return results;
    } catch (error) {
      console.log('ERROR during search')
      console.log(error);
      throw error;
    }
  }

  loadHereCategories = async (getAccessToken) => {
    console.log('Loading HERE category information from API...');
    try {
      const token = await getAccessToken();

      const response = await API.get('/admin/v1/ld/place-provider/here/categories', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const hereCategories = response.data.categories;
      console.log(`Loaded ${hereCategories.length} level 1 HERE categories.`);

      this.cachedHereCategories = hereCategories;
      return hereCategories;
    } catch (error) {
      console.log('ERROR when loading HERE categories:')
      console.log(error);
      throw error;
    }
  }

  /**
   * [loadHereCategoryMapping description]
   *
   * @param  {[type]}  getAccessToken async function that returns an accessToken
   * @return {Promise}                [description]
   */
  loadHereCategoryMapping = async (getAccessToken) => {
    console.log('Loading HERE category mapping from API...');
    try {
      const token = await getAccessToken();

      const response = await API.get('/admin/v1/ld/place-provider/here/category-mapping', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const mappingState = response.data;
      console.log(`Loaded mapping! last modified: ${mappingState.modifiedAt}`);

      return mappingState.mapping;
    } catch (error) {
      console.log('ERROR when loading HERE category mapping:')
      console.log(error);
      throw error;
    }
  }

  /**
   * Updats the HERE category ampping in the backend.
   *
   * @param  {[type]}  getAccessToken async function that returns an accessToken
   * @param  {[type]}  mapping        [description]
   * @return {Promise}                [description]
   */
  updateHereCategoryMapping = async (getAccessToken, mapping) => {
    try {
      const token = await getAccessToken();

      const response = await API.put('/admin/v1/ld/place-provider/here/category-mapping', {
        mapping: mapping,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      console.log(`HERE category mapping updated (response status: ${response.status})`);
    } catch (error) {
      console.log('ERROR when saving HERE category mapping:')
      console.log(error);
    }
  }

  excludePlace = async (getAccessToken, placeResource, comment) => {
    console.log('excluding place: ' + placeResource.id);
    console.log('comment: ' + comment);
    const exclusionLink = placeResource['_links']['bdy:exclude'];
    if (exclusionLink) {
      console.log('link is present');
      try {
        const token = await getAccessToken();

        // POST to exclude place
        const response = await API.post(exclusionLink.href,
         {
           'comment': comment !== '' ? comment : undefined,
         }, // empty requets body
         {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        console.log(`External place excluded from future results(response status: ${response.status})`);
      } catch (error) {
        console.log('ERROR when excluding place ' + placeResource.id);
        console.log(error);
      }
    } else {
      throw Error('Cannot exclude place. no exclusion link present');
    }
  }

  stopPlaceExclusion = async (getAccessToken, placeExclusion, comment) => {
    console.log('stopping exclusion for place: ' + placeExclusion.placeId);
    console.log('comment: ' + comment);
    const stopLink = placeExclusion['_links']['bdy:stop'];
    if (stopLink) {
      try {
        const token = await getAccessToken();

        // POST to exclude place
        const response = await API.post(stopLink.href,
         {
           'comment': comment !== '' ? comment : undefined,
         }, // empty requets body
         {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        console.log(`External place will be included in future results (response status: ${response.status})`);
      } catch (error) {
        console.log('ERROR when stopping palce exclusion' + placeExclusion.id);
        console.log(error);
      }
    } else {
      console.log(placeExclusion);
      throw Error('Cannot stop place exclusion. no stop link present');
    }
  }

  getPlaceExclusionDetails = async (getAccessToken, placeResource) => {
    const exclusionLink = placeResource['_links']['bdy:exclusion-details'];
    if (exclusionLink) {
      console.log('link is present');
      try {
        const token = await getAccessToken();

        // GET to get the detals
        const response = await API.get(exclusionLink.href,
         {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        console.log(`PlaceExclusion loaded (response status: ${response.status})`);
        if (response.status === 404) {
          // expected status code -> no exclusion details
          return undefined;
        }
        return response.data;

      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log('no exclusion details for palce: ' + placeResource.id)
          return undefined;
        }
        console.log('ERROR when loading place exclusion for place ' + placeResource.id);
        console.log(error);
      }
    } else {
      throw Error('Cannot get place exclusion details. no link present');
    }
  }
}
