import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ListItemText from '@material-ui/core/ListItemText';

import { useAuth0 } from "@auth0/auth0-react";

import PlaceService from './../core/PlaceService';

const placeService = new PlaceService();

function PlaceExclusionDetails(props) {
  const {
    place,
    onPlaceExcluded,
    onPlaceExclusionStopped
  } = props;

  const [placeExclusion, setPlaceExclusion] = React.useState({});

  const { getAccessTokenSilently } = useAuth0();

  /*
   * Important! declare 'place.excluded' as dependency so details are fetched
   * after exclusion state changed.
   *
   * Note: other option would be to call 'setPlaceExclusion' with response from
   * dialogs...
   */
  React.useEffect(() => {
    placeService.getPlaceExclusionDetails(getAccessTokenSilently, place)
      .then(e => setPlaceExclusion(e));
  }, [getAccessTokenSilently, place, place.excluded]);

  return (
    <div>
      <h4>PlaceExclusion - Details</h4>
      {
        // Only show data if exclusion exists
        placeExclusion && <PlaceExclusionData placeExclusion={placeExclusion} />
      }
      <div>
        {
          place.excluded ?

            (<StopPlaceExclusionDialog
              placeService={placeService}
              placeExclusion={placeExclusion}
              onPlaceExclusionStopped={() => onPlaceExclusionStopped(place)}
            />)

            : (<ExcludePlaceDialog
              placeService={placeService}
              place={place}
              onPlaceExcluded={() => onPlaceExcluded(place)}
            />)
        }
      </div>
    </div>
  );
}

function PlaceExclusionData(props) {
  const {
    placeExclusion
  } = props;

  return (
    <>
    <div>
      <ul>
        <li>
          Zuerst ausgeblendet von: {placeExclusion.createdByName}
        </li>
        <li>
          state: {placeExclusion.state}
        </li>
        <li>
          createdAt: {placeExclusion.createdAt}
        </li>
        <li>
          modifiedAt: {placeExclusion.modifiedAt}
        </li>
      </ul>
    </div>
    <h5>Verlauf</h5>
    <ul dense={true}>
      {
        (placeExclusion.comments || []).map(comment =>
          <li key={comment}>
            <ListItemText
              secondary={comment} />
          </li>
        )
      }
    </ul>
    </>
  );
}

function ExcludePlaceDialog(props) {
  const {
    placeService,
    place,
    onPlaceExcluded,
  } = props;

  const { getAccessTokenSilently } = useAuth0();

  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState('');
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExcludePlace = async () => {

    var success = false;
    try {
      await placeService.excludePlace(getAccessTokenSilently, place, comment);

      success = true;
    } catch (error) {
      console.log('ERROR: Failed to exclude place');
      console.log(error);
    }

    if (success) {
      // Only close on success
      setOpen(false);

      onPlaceExcluded();
    }
  };

  return (
    <div>
      <Button variant="outlined" color="secondary" onClick={handleClickOpen}>
        Ort ausblenden
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Ort aus Ergebnissen entfernen?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Stelle sicher das es nicht klüger ist das Kategorie Mapping anzupassen um sowohl diesen
            als auch auch ähnliche, ungewollte Orte auszublenen.
          Ausgeblendete Orte kannst du später auch wieder einblenden.
          </DialogContentText>
          <TextField
            value={comment}
            onChange={handleCommentChange}
            autoFocus
            multiline
            margin="dense"
            id="comment"
            label="Grund / Kommentar"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleExcludePlace} color="secondary" autoFocus>
            Ort ausblenden
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function StopPlaceExclusionDialog(props) {
  const {
    placeService,
    placeExclusion,
    onPlaceExclusionStopped,
  } = props;

  const { getAccessTokenSilently } = useAuth0();

  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState('');
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStopPlaceExclusion = async () => {

    var success = false;
    try {
      await placeService.stopPlaceExclusion(getAccessTokenSilently, placeExclusion, comment);

      success = true;
    } catch (error) {
      console.log('ERROR: Failed to stop place exclusion');
      console.log(error);
    }

    if (success) {
      // Only close on success
      setOpen(false);

      onPlaceExclusionStopped();
    }
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Wieder Einblenden
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Ort wieder in Ergebnissen anzeigen?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Notiere am besten warum der Ort wieder angezeigt werden soll.
          </DialogContentText>
          <TextField
            value={comment}
            onChange={handleCommentChange}
            autoFocus
            multiline
            margin="dense"
            id="comment"
            label="Grund / Kommentar"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleStopPlaceExclusion} color="secondary" autoFocus>
            Wieder Einblenden
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PlaceExclusionDetails;
