import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CancelIcon from '@material-ui/icons/Cancel';

import PlaceService from './PlaceService';

import { withAuth0 } from '@auth0/auth0-react';

/*
 * TODO Refactor whole code and rename 'selection' to 'mapping'! (as it is used in backend code)
 */

const styles = {
  root: {
  },
  media: {
    height: 140,
  },
  table: {
    minWidth: 650,
  },
  idCell: {

  }
};



const SELECTED = 'INCLUDE';
const UNSELECTED = undefined;
const EXCLUDED = 'EXCLUDE';
const INDETERMINATE = 'PARTIALLY';



class HereCategoriesView extends React.Component {

  placeService = new PlaceService();

  constructor(props) {
    super(props);

    this.state = {
      categorySelection: undefined,
      allHereCategories: undefined
    }
  }

  componentDidMount() {
    this.loadCategories()
      .then(() => {
        console.log('loading mapping after categories loaded')
        this.loadMapping();
      });
  }

  loadCategories = async () => {
    const {
      getAccessTokenSilently
    } = this.props.auth0;

    // Only pass function from Auth0 to service
    console.log('laoding categories');
    const hereCategories = await this.placeService.loadHereCategories(getAccessTokenSilently);
    console.log('categories loaded');
    console.log(hereCategories);

    this.setState({
      allHereCategories: hereCategories
    });
  }

  /**
   * Loads the category mapping from API and initializes the internal
   * 'selectionMap' that holds state of the checkboxes.
   *
   * @return {Promise} [description]
   */
  loadMapping = async () => {
    const {
      getAccessTokenSilently
    } = this.props.auth0;

    // Only pass function from Auth0 to service
    const mapping = await this.placeService.loadHereCategoryMapping(getAccessTokenSilently);

    // Build a 'selection'-map from API data
    const newSelection = this.createCategorySelectionMapFromApiCategoryMapping(mapping);

    console.log('Created new selection map from API result');
    console.log(newSelection);

    this.setState({
      categorySelection: newSelection
    });
  }

  render() {
    if (this.state.allHereCategories && this.state.categorySelection) {
      return this.renderWithCategoriesLoaded();
    } else {
      return (
        <p>Loading categories...</p>
      );
    }
  }

  renderWithCategoriesLoaded = () => {
    const {
      classes,
      onCategoryMappingChanged
    } = this.props;

    const {
      allHereCategories
    } = this.state;

    const rows = allHereCategories;


    const handleCategorySelectChange = (categoryId, buddyCategoryId) => {
      console.log('handle change called!');
      this.setState((prevState) => {
        const nextSelection = new Map(prevState.categorySelection);

        const prevSelectionState = nextSelection.get(categoryId)[buddyCategoryId];
        /*
         * The next state of the chnaged category.
         *
         * UNSELECTED -> SELECTED
         * SELECTED   -> EXCLUDED
         * EXCLUDED   -> UNSELECTED
         */
        var nextSelectionState;
        if (prevSelectionState === UNSELECTED) {
          nextSelectionState = SELECTED;
        } else if (prevSelectionState === SELECTED) {
          nextSelectionState = EXCLUDED;
        } else {
          nextSelectionState = UNSELECTED;
        }
        console.log('category ' + categoryId + ', buddyId=' + buddyCategoryId + ' :: ' + nextSelectionState);
        console.log(prevSelectionState + ' -> ' + nextSelectionState);


        this.setCategorySelection(nextSelection, categoryId, buddyCategoryId, nextSelectionState);

        if (categoryId.length === 3) {
          // we have a level 1 category -> change all sub category states
          for (const cat2 of this.state.allHereCategories.find((c) => c.id === categoryId).subCategories) {

            this.setCategorySelection(nextSelection, cat2.id, buddyCategoryId, nextSelectionState);

            for (const cat3 of cat2.subCategories) {
              this.setCategorySelection(nextSelection, cat3.id, buddyCategoryId, nextSelectionState);
            }
          }
        } else if (categoryId.length === 8) {
          // we have a level 2 category --> change sub category states
          const category = this.getCategoryById(categoryId);

          // select / unselect all sub categories
          for (const cat3 of category.subCategories) {
            this.setCategorySelection(nextSelection, cat3.id, buddyCategoryId, nextSelectionState);
          }

          // set parent state
          this.adjustParentCategorySelection(nextSelection, categoryId, buddyCategoryId);
        } else {
          // assume we have a level 3 category
          console.log('adjusting parent of ' + categoryId);
          this.adjustParentCategorySelection(nextSelection, categoryId, buddyCategoryId);
        }


        const categoryMapping = {};
        categoryMapping[PlaceService.ACTION] = [];
        categoryMapping[PlaceService.CULTURE] = [];
        categoryMapping[PlaceService.FOOD_DRINK] = [];
        categoryMapping[PlaceService.WELLNESS] = [];
        categoryMapping[PlaceService.NIGHTLIFE] = [];
        for (let [id, value] of nextSelection) {
          if (value[PlaceService.ACTION] === SELECTED) {
            categoryMapping[PlaceService.ACTION].push(id);
          }
          if (value[PlaceService.CULTURE] === SELECTED) {
            categoryMapping[PlaceService.CULTURE].push(id);
          }
          if (value[PlaceService.FOOD_DRINK] === SELECTED) {
            categoryMapping[PlaceService.FOOD_DRINK].push(id);
          }
          if (value[PlaceService.WELLNESS] === SELECTED) {
            categoryMapping[PlaceService.WELLNESS].push(id);
          }
          if (value[PlaceService.NIGHTLIFE] === SELECTED) {
            categoryMapping[PlaceService.NIGHTLIFE].push(id);
          }
        }
        onCategoryMappingChanged(categoryMapping);

        return { categorySelection: nextSelection };
      });
    };

    return (
      <Card className={classes.root}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            HERE Categories
            </Typography>


          <TableContainer>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Category</TableCell>
                  <TableCell align="left">ID</TableCell>

                  <TableCell>
                    <Chip avatar={<Avatar src="/images/category_action.png" />} />
                  </TableCell>
                  <TableCell>
                    <Chip avatar={<Avatar src="/images/category_culture.png" />} />
                  </TableCell>
                  <TableCell>
                    <Chip avatar={<Avatar src="/images/category_food.png" />} />
                  </TableCell>
                  <TableCell>
                    <Chip avatar={<Avatar src="/images/category_wellness.png" />} />
                  </TableCell>
                  <TableCell>
                    <Chip avatar={<Avatar src="/images/category_nightlife.png" />} />
                  </TableCell>
                  <TableCell align="left">Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <Level1CategoryRow
                    key={row.name}
                    row={row}
                    handleCategorySelectChange={handleCategorySelectChange}
                    categorySelection={this.state.categorySelection} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" disabled>
            Apply (locally)
        </Button>
          <Button size="small" color="primary" onClick={this.saveMapping}>
            Save
        </Button>
        </CardActions>
      </Card>
    );
  }

  saveMapping = async () => {
    const {
      getAccessTokenSilently
    } = this.props.auth0;

    const {
      allHereCategories
    } = this.state;

    console.log('saving mapping...');

    const completeMapping = {}
    for (const buddyCat of PlaceService.CATEGORIES) {
      /*
       *  Use the list of all level 1 HERE categories as input to start creating
       *  the mapping recursivly.
       */
      completeMapping[buddyCat] = this.createApiMappingForBuddyCategory(buddyCat, allHereCategories);
    }

    console.log('API mapping:');
    console.log(completeMapping);

    await this.placeService.updateHereCategoryMapping(getAccessTokenSilently, completeMapping);
  }

  /**
   * Returns an array, defining the mapping for the given BUDDY category
   * in the format to be sent t the API.
   *
   * @param  {[type]} buddyCategoryId [description]
   * @return {[type]}                 [description]
   */
  createApiMappingForBuddyCategory = (buddyCategoryId, hereCategories) => {
    // Holds the level 1 category mapping
    const mappingList = [];

    for (const cat of hereCategories) {

      const mappingType = this.state.categorySelection.get(cat.id)[buddyCategoryId];
      // Only add mappings which are not 'unselected'
      if (mappingType !== UNSELECTED) {

        const categoryMapping = {
          id: cat.id,
          mappingType: mappingType
        };
        // Partial mapping
        if (mappingType === INDETERMINATE) {
          // Map sub-categories (recursivly)
          categoryMapping.subCategoryMapping = this.createApiMappingForBuddyCategory(buddyCategoryId, cat.subCategories);
        }

        mappingList.push(categoryMapping);
      }
    }

    return mappingList;
  }

  /*
   * sets parent categry selection state based on the sub categories state.
   * If all subcategories are selected / unselected the parent state is also set
   * selected / unselected. Otherwise it is set to INDETERMINATE.
   */
  adjustParentCategorySelection = (nextSelection, categoryId, buddyCategoryId) => {
    const parent = this.getParentCategory(categoryId);

    var allSubCategoriesSelected = true;
    var allSubCategoriesUnselected = true;
    var allSubCategoriesExcluded = true;
    for (const cat2 of parent.subCategories) {
      const cat2State = nextSelection.get(cat2.id);
      if (cat2State[buddyCategoryId] !== SELECTED) {
        // claim falsified
        allSubCategoriesSelected = false;
      }
      if (cat2State[buddyCategoryId] !== UNSELECTED) {
        // claim falsified
        allSubCategoriesUnselected = false;
      }
      if (cat2State[buddyCategoryId] !== EXCLUDED) {
        // claim falsified
        allSubCategoriesExcluded = false;
      }
      // Note: INDETERMINATE sets all three claims to false
    }
    if (allSubCategoriesSelected) {
      this.setCategorySelection(nextSelection, parent.id, buddyCategoryId, SELECTED);
    } else if (allSubCategoriesUnselected) {
      this.setCategorySelection(nextSelection, parent.id, buddyCategoryId, UNSELECTED);
    } else if (allSubCategoriesExcluded) {
      this.setCategorySelection(nextSelection, parent.id, buddyCategoryId, EXCLUDED);
    } else {
      this.setCategorySelection(nextSelection, parent.id, buddyCategoryId, INDETERMINATE);
    }

    if (parent.id.length === 8) {
      // level 2 parent -> adjust level 1 parent
      this.adjustParentCategorySelection(nextSelection, parent.id, buddyCategoryId);
    }
  }

  /**
   * Modifies the selection type of a signle HERE category <-> BUDDY categiry mapping in a
   * map representing the current mapping state.
   *
   * NOTE: Use this function to change the value in the map in a React 'setState'-save way!
   */
  setCategorySelection = (selectionMap, hereCategoryId, buddyCategoryId, selectionType) => {
    /*
     * NOTE: Important to create a new object for the category which we
     * change! Otherwise we get some strange behavior!
     */
    selectionMap.set(hereCategoryId, {
      ...(selectionMap.get(hereCategoryId)),
      [buddyCategoryId]: selectionType
    });
  }

  createCategorySelectionMapFromApiCategoryMapping = (mapping) => {
    // Build a 'selection'-map from API data
    const newSelection = new Map();

    // Initialize new map with all unselected
    for (const cat1 of this.state.allHereCategories) {
      newSelection.set(cat1.id, {});
      for (const cat2 of cat1.subCategories) {
        newSelection.set(cat2.id, {});
        for (const cat3 of cat2.subCategories) {
          newSelection.set(cat3.id, {});
        }
      }
    }

    for (const buddyCategory of PlaceService.CATEGORIES) {

      const level1Mappings = mapping[buddyCategory];
      for (const cat1Mapping of level1Mappings) {
        this.setCategorySelection(newSelection, cat1Mapping.id, buddyCategory, cat1Mapping.mappingType);

        // If not PARTIALLY mapping we need to set all sub categories to same value
        if (cat1Mapping.mappingType === INDETERMINATE) {

          for (const cat2Mapping of cat1Mapping.subCategoryMapping || []) {
            this.setCategorySelection(newSelection, cat2Mapping.id, buddyCategory, cat2Mapping.mappingType);

            if (cat2Mapping.mappingType === INDETERMINATE) {

              for (const cat3Mapping of cat2Mapping.subCategoryMapping || []) {
                this.setCategorySelection(newSelection, cat3Mapping.id, buddyCategory, cat3Mapping.mappingType);
              }
            } else {
              const parentMappingType = cat2Mapping.mappingType;

              for (const cat3 of this.getCategoryById(cat2Mapping.id).subCategories) {
                this.setCategorySelection(newSelection, cat3.id, buddyCategory, parentMappingType);
              }
            }
          }
        } else {

          const parentMappingType = cat1Mapping.mappingType;

          // All same as parent
          for (const cat2 of this.getCategoryById(cat1Mapping.id).subCategories) {
            this.setCategorySelection(newSelection, cat2.id, buddyCategory, parentMappingType);
            for (const cat3 of cat2.subCategories) {
              this.setCategorySelection(newSelection, cat3.id, buddyCategory, parentMappingType);
            }
          }
        }

      }
    }
    return newSelection;
  }

  getParentCategory = (id) => {
    if (id.length === 8) {
      // level 2
      return this.state.allHereCategories.find((c1) => c1.subCategories.some(c2 => c2.id === id));
    } else {
      // assume it's a level 3 category
      for (const cat1 of this.state.allHereCategories) {
        if (cat1.id === id) {
          throw Error('ERROR: categoryId ' + id + ' is level 1! (no parent)');
        } else {
          const parent = cat1.subCategories.find((c2) => c2.subCategories.some(c3 => c3.id === id));
          if (parent) {
            return parent;
          } else {
            // continue searching
          }
        }
      }
    }
    throw Error('Parent of category ' + id + ' not found');
  }

  /*
   * Wraps logic for finding a category by ID.
   * (currently very bad performance because of our storage format!);
   */
  getCategoryById = (id) => {
    for (const cat1 of this.state.allHereCategories) {
      if (cat1.id === id) {
        return cat1;
      } else {
        for (const cat2 of cat1.subCategories) {
          if (cat2.id === id) {
            return cat2;
          }
          for (const cat3 of cat2.subCategories) {
            if (cat3.id === id) {
              return cat3;
            }
          }
        }
      }
    }
  }
}


// CLASS ENDS HERE ===

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  idCell: {
    width: '100px'
  }
});

function Level1CategoryRow(props) {
  const {
    row,
    handleCategorySelectChange,
    categorySelection
  } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="left">{row.id}</TableCell>
        <TableCell>
          <CategoryCheckbox
            hereCategoryId={row.id}
            buddyCategoryId={PlaceService.ACTION}
            onChange={handleCategorySelectChange}
            categorySelection={categorySelection}
          />
        </TableCell>
        <TableCell>
          <CategoryCheckbox
            hereCategoryId={row.id}
            buddyCategoryId={PlaceService.CULTURE}
            onChange={handleCategorySelectChange}
            categorySelection={categorySelection}
          />
        </TableCell>
        <TableCell>
          <CategoryCheckbox
            hereCategoryId={row.id}
            buddyCategoryId={PlaceService.FOOD_DRINK}
            onChange={handleCategorySelectChange}
            categorySelection={categorySelection}
          />
        </TableCell>
        <TableCell>
          <CategoryCheckbox
            hereCategoryId={row.id}
            buddyCategoryId={PlaceService.WELLNESS}
            onChange={handleCategorySelectChange}
            categorySelection={categorySelection}
          />
        </TableCell>
        <TableCell>
          <CategoryCheckbox
            hereCategoryId={row.id}
            buddyCategoryId={PlaceService.NIGHTLIFE}
            onChange={handleCategorySelectChange}
            categorySelection={categorySelection}
          />
        </TableCell>
        <TableCell align="left">{row.description}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                {row.name} - Sub-Categories
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Category</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>
                      <Chip avatar={<Avatar src="/images/category_action.png" />} />
                    </TableCell>
                    <TableCell>
                      <Chip avatar={<Avatar src="/images/category_culture.png" />} />
                    </TableCell>
                    <TableCell>
                      <Chip avatar={<Avatar src="/images/category_food.png" />} />
                    </TableCell>
                    <TableCell>
                      <Chip avatar={<Avatar src="/images/category_wellness.png" />} />
                    </TableCell>
                    <TableCell>
                      <Chip avatar={<Avatar src="/images/category_nightlife.png" />} />
                    </TableCell>
                    <TableCell align="left">Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.subCategories.map((category) => (
                    <Level2CategoryRow key={category.id}
                      row={category}
                      handleCategorySelectChange={handleCategorySelectChange}
                      categorySelection={categorySelection} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function Level2CategoryRow(props) {
  const {
    row,
    handleCategorySelectChange,
    categorySelection
  } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="left">{row.id}</TableCell>
        <TableCell>
          <CategoryCheckbox
            hereCategoryId={row.id}
            buddyCategoryId={PlaceService.ACTION}
            onChange={handleCategorySelectChange}
            categorySelection={categorySelection}
          />
        </TableCell>
        <TableCell>
          <CategoryCheckbox
            hereCategoryId={row.id}
            buddyCategoryId={PlaceService.CULTURE}
            onChange={handleCategorySelectChange}
            categorySelection={categorySelection}
          />
        </TableCell>
        <TableCell>
          <CategoryCheckbox
            hereCategoryId={row.id}
            buddyCategoryId={PlaceService.FOOD_DRINK}
            onChange={handleCategorySelectChange}
            categorySelection={categorySelection}
          />
        </TableCell>
        <TableCell>
          <CategoryCheckbox
            hereCategoryId={row.id}
            buddyCategoryId={PlaceService.WELLNESS}
            onChange={handleCategorySelectChange}
            categorySelection={categorySelection}
          />
        </TableCell>
        <TableCell>
          <CategoryCheckbox
            hereCategoryId={row.id}
            buddyCategoryId={PlaceService.NIGHTLIFE}
            onChange={handleCategorySelectChange}
            categorySelection={categorySelection}
          />
        </TableCell>
        <TableCell align="left">{row.description}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                {row.name} - Sub-Categories
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Category</TableCell>
                    <TableCell className={classes.idCell}>ID</TableCell>
                    <TableCell>
                      <Chip avatar={<Avatar src="/images/category_action.png" />} />
                    </TableCell>
                    <TableCell>
                      <Chip avatar={<Avatar src="/images/category_culture.png" />} />
                    </TableCell>
                    <TableCell>
                      <Chip avatar={<Avatar src="/images/category_food.png" />} />
                    </TableCell>
                    <TableCell>
                      <Chip avatar={<Avatar src="/images/category_wellness.png" />} />
                    </TableCell>
                    <TableCell>
                      <Chip avatar={<Avatar src="/images/category_nightlife.png" />} />
                    </TableCell>
                    <TableCell align="left">Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.subCategories.map((category) => (
                    <TableRow key={category.id}>
                      <TableCell component="th" scope="row">{category.name}</TableCell>
                      <TableCell align="left" className={classes.idCell}>{category.id}</TableCell>
                      <TableCell>
                        <CategoryCheckbox
                          hereCategoryId={category.id}
                          buddyCategoryId={PlaceService.ACTION}
                          onChange={handleCategorySelectChange}
                          categorySelection={categorySelection}
                        />
                      </TableCell>
                      <TableCell>
                        <CategoryCheckbox
                          hereCategoryId={category.id}
                          buddyCategoryId={PlaceService.CULTURE}
                          onChange={handleCategorySelectChange}
                          categorySelection={categorySelection}
                        />
                      </TableCell>
                      <TableCell>
                        <CategoryCheckbox
                          hereCategoryId={category.id}
                          buddyCategoryId={PlaceService.FOOD_DRINK}
                          onChange={handleCategorySelectChange}
                          categorySelection={categorySelection}
                        />
                      </TableCell>
                      <TableCell>
                        <CategoryCheckbox
                          hereCategoryId={category.id}
                          buddyCategoryId={PlaceService.WELLNESS}
                          onChange={handleCategorySelectChange}
                          categorySelection={categorySelection}
                        />
                      </TableCell>
                      <TableCell>
                        <CategoryCheckbox
                          hereCategoryId={category.id}
                          buddyCategoryId={PlaceService.NIGHTLIFE}
                          onChange={handleCategorySelectChange}
                          categorySelection={categorySelection}
                        />
                      </TableCell>
                      <TableCell align="left">{category.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function CategoryCheckbox(props) {
  const {
    hereCategoryId,
    buddyCategoryId,
    onChange,
    categorySelection
  } = props;

  /*
   * Mapping state of the checkbox. Either SELECTED, EXCLUDED, UNSELECTED,
   * or (in case there are different child selections) INDETERMINATE
   */
  const selection = categorySelection.get(hereCategoryId)[buddyCategoryId];

  return (
    <Checkbox
      onChange={(event) => {onChange(hereCategoryId, buddyCategoryId)}}
      checked={selection === SELECTED || selection === EXCLUDED}
      indeterminate={selection === INDETERMINATE}
      checkedIcon={selection === EXCLUDED ? <CancelIcon /> : <CheckBoxIcon />}
      color={selection === EXCLUDED ? 'secondary' : 'primary'}
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    />
  );
}

export default withAuth0(withStyles(styles)(HereCategoriesView));
